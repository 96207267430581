@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown {
    /* other styles for the dropdown menu */
    position: relative;
}

.dropdown::before {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
}

html {
    background-color: hsla(0, 0%, 87%, 0.8);
}
